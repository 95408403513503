body {
    font-family: Inter;
    overflow-x: hidden;
}

.background-image {
    height: 100vh;
}

.card {
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    background: #ffffff;
}

h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #05280d;
}

a {
    text-decoration: none;
    color: #00781a;
}

a:hover {
    text-decoration: underline;
    color: #00781a;
}

.input-container i {
    position: absolute;
}

.input-container {
    display: block;
    width: 100%;
}

.icon {
    font-size: 16px;
    padding: 10px;
    color: green;
    min-width: 50px;
    text-align: center;
    padding-top: 12px;
}

.input-field {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    background-color: #ffffff;
}

.forgot-password {
    font-size: 16px;
}

.submit-btn {
    background-color: #00781a;
    color: white;
    font-size: 14px;
    line-height: 24px;
}

.submit-btn:hover {
    color: white;
}
